import { PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addPageAction } from "../common/newrelic"

const samplingPercentage = 5 // Sample 5%

export function getOrCreateSampleIDCache(): number {
    const stored = window.sessionStorage.getItem("pageaction_sample_id")
    if (stored !== null) {
        return parseInt(stored)
    }
    const sampleID = Math.floor(Math.random() * 100) + 1
    window.sessionStorage.setItem("pageaction_sample_id", sampleID.toString())
    return sampleID
}

export function shouldSamplePageActions(): boolean {
    const sampleID = getOrCreateSampleIDCache()
    return sampleID <= samplingPercentage
}

let trackAllClicksFn: (event: MouseEvent) => void

export function handleClick(source: string, clickedElement: HTMLElement): void {
    if (clickedElement !== null && clickedElement.childNodes.length <= 3) { // Don't track large outer parent divs
        const location = getLocationFromParent(clickedElement)
        if (location !== null && location !== "" && location !== undefined) {
            const name = getName(clickedElement)
            const parent = clickedElement.parentElement
            if (parent !== null && parent.dataset["pactionSendPosition"] === "true") {
                const index = Array.prototype.indexOf.call(parent.children, clickedElement)
                const count = parent.childElementCount
                addPageAction(source, { "location": location, "name": name, "index": index, "count": count })
            } else {
                addPageAction(source, { "location": location, "name": name })
            }
        }
    }
}

export function trackAllClicks(source?: string): void {
    if (shouldSamplePageActions()) {
        if (trackAllClicksFn !== undefined) {
            removeTrackAllClicks()
        }
        trackAllClicksFn = (event: MouseEvent) => {
            const clickedElement = event.target as HTMLElement
            if (clickedElement.getAttribute("name") === "visible_genders") {
                return
            } // Prevent gender checkboxes from triggering twice
            handleClick(source ?? getPageSource(), clickedElement)
        }
        document.addEventListener("click", trackAllClicksFn, true)
    }

    function getPageSource() {
        const pageType = UrlState.current.state.pageType;
        return `UIClick${pageType === PageType.ROOM ? "Chatroom" : pageType === PageType.DISCOVER ? "Discovery" :
            "Homepage"}`
    }
}

function removeTrackAllClicks(): void {
    if (trackAllClicksFn !== undefined) {
        document.removeEventListener("click", trackAllClicksFn, true)
    }
}

export function getName(clickedElement: HTMLElement): string {
    return (
            getNameFromParent(clickedElement) ||
            clickedElement.title ||
            clickedElement.innerText ||
            (clickedElement.getAttribute("value") ?? "") ||
            ""
        )
}

export function getNameFromParent(element: HTMLElement): string {
    const name = element.dataset["pactionName"]
    if (name !== undefined) {
        return name
    }
    while (element.parentElement) {
        element = element.parentElement
        const name = element.dataset["pactionName"]
        if (name !== undefined) {
            return name
        } else if (element.title !== null) {
            return element.title
        }
    }
    return ""
}

export function getLocationFromParent(element: HTMLElement): string {
    const location = element.dataset["paction"]
    if (location !== undefined) {
        return location
    }
    while (element.parentElement) {
        element = element.parentElement
        const location = element.dataset["paction"]
        if (location !== undefined) {
            return location
        }
    }
    return ""
}
